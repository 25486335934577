<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="row mb-2">
                <h3>{{ $t('general.listadodecorreos') }}</h3>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-2">
                </div>
                <div class="col-md-10">
                    <tabla-datos :columnas="columnas"  :datos="correos" :filtros="filtros" :filtrosBuscador="filtrosBuscador"
                        :total="total" @quieroDatos="obtenerCorreos">
                    </tabla-datos>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import TablaDatos from '../../TablaDatos.vue';
export default {
    components: {
        "tabla-datos": TablaDatos,
    },
    data() {
        return {
            total: '',
            correos: [],
            parametros: null, //query params de DataTable
            filtrosBuscador: ['nombre_banco'], //filtros para el buscador
            filtros: { //filtros de columna
                'nombre_correo': { value: '', matchMode: 'contains' },
                'direccion_correo': { value: '', matchMode: 'contains' },
            },
            columnas: [
                {
                    header: 'Nombre',
                    field: 'nombre_correo',
                    sortable: true,
                },
                {
                    header: 'Direccion',
                    field: 'direccion_correo',
                    sortable: true,
                },
                {
                    header: 'Usuario',
                    field: 'usuario_correo',
                    sortable: true,
                },
                {
                    header: 'Acciones',
                    data: null,
                    acciones: [
                        {
                            nombre: 'Editar',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'id_correo',
                            icono: 'far fa-edit',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Editar correo ' + id);
                                this.$router.push({
                                    name: 'Correo',
                                    params: {
                                        id
                                    }
                                });
                            }
                        },
                        {
                            nombre: 'Eliminar',
                            class: 'eliminar',
                            tipo: 'danger',
                            nombreId: 'id_correo',
                            icono: 'far fa-trash-alt',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Eliminar correo ' + id);
                                if (confirm('Desea eliminar el correo')) {
                                    this.eliminar(id);
                                }
                            }
                        },
                    ]
                }
            ]
        }
    },
    //  modulos/pwgsapi/index.php/correos/:id/conexion
    //  modulos/pwgsapi/index.php/correos/0/movilidad
    methods: {
        async obtenerCorreos(parametros) {
            const api = new PwgsApi;
            const params = parametros;
            const aux = await api.get('correos', params);
            this.correos = aux.datos;
            this.total = aux.n_total_registros;
            this.columnas[0].header = this.$t("general.nombre");
            this.columnas[1].header = this.$t("general.localidad");
            this.columnas[2].header = this.$t("general.usuario");
            this.columnas[3].header = this.$t("general.acciones");
        },
        async eliminar(id) {
            console.log('eliminando correo ' + id + '...');
            const api = new PwgsApi;
            await api.delete('correos/' + id);
            this.obtenerBancos();
        },
    },
    mounted() {
        this.obtenerCorreos();
    }
}
</script>
<style>
.p-dialog {
    width: 90%;
}
</style>